// sass-lint:disable no-important no-vendor-prefixes nesting-depth
$message-filter-lineheight: 36px;
$message-personal-image-thread: 40px;
$message-personal-image-header: 50px;
$message-personal-image-message: 30px;
$message-bd: 1px solid $bdcolor-normal;

$message-threads-module-width: 260px;

$icon-size-button-back: 24px;
$font-size-header-name: 24px;
$font-size-header-company: 18px;
$font-size-contents-name: 18px;
$height-message-button: 30px;

$modal-shadow: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 45.74%, $color-white 100%);

.message {
  &-module {
    @include clearfix;
    position: relative;
    height: calc(100dvh - 60px);
    border: $message-bd;
    border-width: 0 1px;
    overflow: hidden;

    @include mq-sp {
      display: flex;
      width: 200%;
      height: auto;
      border-top: 0;
      align-items: flex-start;
      transition: transform 10ms;
      background-color: $bgcolor-module-disabled;
    }
  }

  &-trigger-back {
    @extend .icon-arrow-left;
    display: none;
    border: 0;
    appearance: none;
    background: none;
    color: $fgcolor-primary-theme;
    font-size: $icon-size-button-back;

    @include mq-sp {
      display: inline-block;
      position: fixed;
      left: 10px;
      top: 0;
      z-index: 102;
      height: $header-height--sp;
      vertical-align: middle;
      padding-left: 0;
    }
  }

  &-threads-module {
    position: absolute;
    z-index: 1;
    width: $message-threads-module-width;
    height: 100%;
    border-right: $message-bd;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @include mq-sp {
      position: static;
      min-width: 50%;
      overflow: scroll;
      // sass-lint:disable no-misspelled-properties no-vendor-prefixes
      -webkit-overflow-scrolling: touch;
      // knockoutのvisibleだとメディアクエリの制御ができないため、cssでスレッド一覧のdisplayの制御を行なっている
      display: none;

      &--is-active {
        display: block;
      }
    }
  }

  //threads search
  &-threads-search {
    padding: 10px;
    background-color: $bgcolor-primary;

    label {
      display: block;
      position: relative;
      width: 100%;
      padding: 0;
      background-color: $bgcolor-normal;
      box-sizing: border-box;
      @extend .icon-magnifier;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        color: $basecolor-placeholder;
      }

      &::before {
        font-size: $font-size-small;
        height: $font-size-small;
        left: 10px;
      }

      &::after {
        content: "OB/OG名、企業名、本文";
        font-size: $font-size-small;
        height: $font-size-small;
        left: 10 + $font-size-small + 5;
      }

      input {
        display: block;
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: transparent;
        box-sizing: border-box;

        &:focus {
          background-color: $bgcolor-normal;
        }
      }
    }

    + * {
      border-top: $message-bd;
    }
  }

  //threads filters
  &-threads-filter {
    position: relative;

    &-list {
      width: 100%;

      &-wrapper {
        width: 100%;
      }

      > li {
        width: 33.333%;
        margin: 0;
        padding: 0;
        text-align: center;

        &:last-child {
          padding-right: 0;
        }
      }

      label {
        display: block;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }

      input {
        display: none;

        + * {
          position: relative;
          display: block;
          padding: 5px 15px;
          background-color: $bgcolor-normal;
          line-height: $message-filter-lineheight;
          color: $fgcolor-supplemental;
        }

        &:checked {
          + * {
            color: $basecolor-primary;

            &::after {
              display: block;
              position: absolute;
              right: 0;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 0;
              border-bottom: 4px solid $bgcolor-button-primary;
              content: "";

              @include mq-sp {
                border-width: 2px;
              }
            }
          }
        }
      }
    }

    &-unread {
      padding: 5px 10px;
      border-top: $message-bd;
      background-color: $bgcolor-normal;

      &:first-child {
        border-top: 0;
      }

      label {
        display: block;
        cursor: pointer;
      }
    }
  }

  //threads body
  &-threads {
    background-color: $bgcolor-normal;

    &-wrapper {
      position: relative;
      border-top: $message-bd;
      //overflow: auto;
      box-sizing: border-box;
      flex-grow: 1;

      @include mq-sp {
        background-color: $bgcolor-normal;
        min-height: calc(100vh - 167px);
      }
      @include mq-pc {
        overflow: scroll;
      }
    }

    &-info-no-threads {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1em;
      margin: auto;
      color: $fgcolor-supplemental;
      text-align: center;
    }
  }

  //a thread
  &-thread {
    position: relative;
    border-top: $message-bd;
    cursor: pointer;

    &:first-child {
      border-top: 0;
    }

    &:hover {
      background-color: $bgcolor-primary;
    }

    // thread state
    &.is-current {
      cursor: default;

      &::before {
        position: absolute;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color-uiblue;
        content: "";
      }

      @include mq-sp {
        background-color: inherit;

        * {
          color: $fgcolor-normal;
        }

        .message-thread-latest-content {
          color: $fgcolor-supplemental;
        }

        .message-thread-latest-datetime {
          color: $fgcolor-supplemental;
        }
      }
    }

    &.is-unread {
      font-weight: bold;

      .message-thread-latest-content {
        color: $fgcolor-normal;
      }

      .message-thread-latest-datetime {
        font-weight: normal;

        @include mq-sp {
          font-size: $font-size-smaller;
        }
      }
    }

    // thread's information

    &-container {
      padding: 12px 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      &--left {
        background-color: $color-lightgray;
      }
    }

    &-left-area {
      display: flex;
    }

    &-right-area {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-grow: 1;
      align-items: flex-start;
    }

    &-name-contents {
      display: flex;
      align-items: center;
    }

    &-introduce-label {
      font-size: 10px;
      padding: 0 5px;
      border-radius: 4px;
      color: $color-uiblue;
      border: 1px solid $color-uiblue;
      text-align: center;
      height: 16px;
      line-height: 16px;
    }

    &-schedule-adjustment-label {
      display: inline-block;
      font-size: 12px;
      line-height: 1.5;
      padding: 2px 4px;
      box-sizing: border-box;
      color: $fgcolor-primary-theme;
      border: 1px solid $fgcolor-primary-theme;
      border-radius: 4px;

      @include mq-sp {
        font-size: 10px;
      }
    }

    &-latest-content {
      @include text-overflow-flexible;
      font-size: $font-size-small;
      line-height: $font-size-small;
      color: $fgcolor-supplemental;

      .is-system & {
        color: $color-uiblue;
      }

      .is-system.is-self & {
        color: $fgcolor-supplemental;
      }
    }

    &-latest-datetime {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: $font-size-smaller;
      color: $fgcolor-supplemental;
    }

    &-personal-name {
      font-size: 16px;
      line-height: 1;
      margin-right: 8px;
      box-sizing: border-box;
      max-width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include mq-sp {
        max-width: calc(100vw - 136px);
      }
    }
  }

  // personal info: very common in this whole modules
  &-personal-info {
    width: 100%;
    height: 100%;
  }

  &-personal-name {
    @include text-overflow;
    font-size: $font-size-basic;
    line-height: $font-size-large;
    margin-right: 8px;
    box-sizing: border-box;

    &.is-introduce-candidate {
      max-width: 66%;
      @include mq-sp {
        max-width: calc(100vw - 136px);
      }
    }
  }

  &-personal-image {
    @include round-bd-image();
    display: inline-block;

    &[href] {
      &:hover {
        opacity: $opacity-strong;

        @include mq-sp {
          opacity: $opacity-reset;
        }
      }
    }

    &--disabled {
      pointer-events: none;
    }

    .message-thread & {
      width: $message-personal-image-thread;
      height: $message-personal-image-thread;
    }

    .message-contents-module-main & {
      width: $message-personal-image-message;
      height: $message-personal-image-message;
    }

    &-wrapper {
      width: $message-personal-image-thread;

      .message-contents-module-main & {
        width: $message-personal-image-message;
      }
    }
  }

  //contents-module
  &-contents-module {
    position: relative;
    height: 100%;
    border-left: $message-threads-module-width transparent solid;

    @include mq-sp {
      width: 50%;
      border-left: 0;
    }

    &-main {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: $bgcolor-normal;
      display: flex;
      flex-direction: column;

      @include mq-sp {
        position: static;
        min-height: calc(100dvh - 45px);
      }

      & .message-personal-name {
        font-size: $font-size-small;
        line-height: 24px;
        max-width: 40em;

        @include mq-sp {
          max-width: 14em;
        }
      }
    }
  }

  &-contents {
    width: 100%;
    padding: 16px 0;
    @include mq-pc {
      margin-bottom: 16px;
    }

    &-wrapper {
      position: relative; // スカウトのリンクをクリック時にスクロールする基準の座標計算につかっている
    }
  }

  &-content {
    position: relative;
    padding: 0 10px;

    .message-datetime {
      position: absolute;
      top: 2px;
      right: 20px;
      font-size: $font-size-smaller;
    }

    & + * {
      margin-top: 24px;

      &.message-status {
        margin-top: 40px;
        @include mq-sp {
          margin-top: 20px;
        }
      }
    }

    .message-introduce-ob {
      display: inline-block;
      position: relative;
      width: 184px;
      height: 119px;
      margin-top: 8px;
      border-radius: 24px;
      background-color: $color-lightgray;

      &__ {
        &link {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          &:hover {
            ~ .message-introduce-ob__contents {
              .message-introduce-ob__contents__name {
                text-decoration: underline;
              }
            }
          }
        }

        &title {
          margin: 16px;
          color: $color-black;
          font-size: $font-size-small;
          font-weight: 600;
          line-height: 14px;
          text-align: center;
        }

        &divider {
          margin: 0;
          color: $color-gray;
        }

        &contents {
          display: flex;
          padding: 16px;
          align-items: center;

          &__ {
            &image {
              display: inline-block;
              @include round-bd-image(40px);
              margin-right: 8px;
            }

            &name {
              width: 87px;
              margin-right: 11px;
              color: $color-black;
              font-size: $font-size-small;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &arrow {
              width: 9px;
              height: 14px;
            }
          }
        }
      }
    }
  }

  &-body {
    line-height: 21px;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: $font-size-small;
  }

  &-body__has-problem {
    display: inline-block;
    padding: 8px;
    max-width: 420px;
    line-height: 21px;
    font-size: $font-size-small;
    color: $color-darkgray;
    border-radius: 7px;
    border: 1px solid $color-gray;
    background-color: $color-lightgray;
    box-sizing: border-box;
    white-space: pre-wrap;
  }

  &-status {
    .message-system-info {
      padding-left: 16px;
      padding-right: 16px;
      font-size: $font-size-smaller;
      line-height: $font-size-smaller * 1.8;
      text-align: center;
    }

    .message-body {
      display: flex;
      color: $fgcolor-supplemental;
      align-items: center;
      justify-content: center;
      font-size: $font-size-smaller;

      &::before,
      &::after {
        flex-basis: 8px;
        height: 1px;
        border-top: 1px solid $bdcolor-strong;
        content: "";
      }

      &::before {
        margin-right: 8px;
      }

      &::after {
        margin-left: 8px;
      }
    }

    + * {
      margin-top: 30px;

      &.message-status {
        margin-top: 40px;
        @include mq-sp {
          margin-top: 20px;
        }
      }
    }
  }

  &-send {
    &-wrapper {
      width: 100%;
      padding: 8px;
      border-top: $message-bd;
      box-sizing: border-box;
      background-color: $bgcolor-normal;
      @include mq-pc {
        position: relative;
      }

      &.is-disabled {
        @include mq-sp {
          padding: 0;
          position: relative;
        }

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: $bgcolor-module-disabled;

          @include mq-sp {
            display: none;
          }
        }
      }
    }

    &-schedule-adjustment-only-warning {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 0 4px;
      margin-bottom: 4px;

      &-icon {
        width: 16px;
        height: 16px;
        background-image: url(/assets/img/message/schedule-adjustment-only-warning.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      &-text {
        font-size: 12px;
      }
    }

    $_textarea-padding: 10px;
    $_textarea-line-height: 24px;

    &-body {
      position: relative;

      &-textarea {
        width: 100%;
        padding-right: 80px;
        box-sizing: border-box;
        resize: none;
        @include mq-sp {
          display: none;
          height: auto;
        }

        &-sp {
          display: none;
          width: 100%;
          height: $_textarea-line-height * 3 + $_textarea-padding;
          padding: 8px;
          font-size: 12px;
          box-sizing: border-box;
          resize: none;

          @include mq-sp {
            display: block;
          }
        }
      }

      .is-disabled & {
        visibility: hidden;
      }
    }

    &-trigger-wrapper {
      text-align: right;

      .is-disabled & {
        visibility: hidden;
      }
    }

    &-trigger {
      @extend .btn-primary;
      position: absolute;
      right: $_textarea-padding;
      bottom: $_textarea-padding;
      min-width: 62px;
      height: $height-message-button;
      line-height: $height-message-button;
      font-size: $font-size-small;
    }

    &-message-disabled {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 3.2em;
      line-height: 1.6;
      margin: auto;
      color: $fgcolor-disabled;
      font-size: $font-size-small;
      text-align: center;
    }

    &__schedule-button {
      @include mq-sp {
        display: none;
      }
    }
  }

  &-notice {
    position: relative;
    text-align: center;
    color: $fgcolor-supplemental;
    padding-top: 30px;
    padding-bottom: 10px;
    font-weight: bold;
    @include mq-sp {
      padding-top: 0;
      font-size: 14px;
    }

    &::before {
      content: "";
      display: block;
      width: 38px;
      height: 36px;
      background: url("/assets/icon/icon-comment.png") no-repeat;
      background-size: contain;
      margin: 0 auto 4px auto;
      @include mq-sp {
        width: 25px;
        height: 24px;
      }
    }

    br {
      @include mq-pc {
        display: none;
      }
    }
  }
}

// layouts like table
// もし汎用layoutとして作ったらreplaceしてください
.l-message {
  &-table {
    display: table;

    &-row {
      display: table-row;
    }

    &-cell {
      display: table-cell;
      padding-left: 10px;
      vertical-align: middle;

      .l-message-contents & {
        vertical-align: top;
      }

      &:last-child {
        padding-right: 10px;
      }
    }

    //appearance
    &-large {
      @extend .l-message-table;

      .l-message-table-cell {
        padding-left: 20px;

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}

.l-messages {
  .l-grid__container {
    padding: 0;
  }
}

.message-detail-header {
  $header-height: 73px;

  &__ {
    &inner {
      display: flex;
      align-items: center;

      &--pc {
        @include mq-sp {
          display: none;
        }
        border-bottom: 1px solid $bdcolor-normal;
      }

      &--sp {
        @include mq-pc {
          display: none;
        }
        flex-wrap: wrap;

        .message-detail-header__profile {
          border-bottom: 1px solid $bdcolor-normal;
        }

        .message-detail-header__button-list {
          border-bottom: 1px solid $color-lightgray;
          justify-content: space-between;
          padding: 8px;
          height: auto;

          .message-detail-header__ob-visit-type-label {
            margin-top: auto;
          }
        }

        .message-detail-header__schedule-button {
          display: block;
          width: 20px;
          height: 20px;
          background: url("/assets/icon/icon-calendar.svg") no-repeat;
          background-size: 20px;
          margin-right: 12px;
        }
      }

      &--left {
        height: $header-height;
        justify-content: center;
        color: $color-darkgray;
        background-color: $color-lightgray;
        border-bottom: 1px solid $bdcolor-normal;
        @include mq-sp {
          font-size: 14px;
        }
      }
    }

    &profile {
      height: $header-height;
      width: 100%;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      //flex-grow: 1;
      background: $bgcolor-normal;
    }

    &avatar {
      height: 40px;
      flex-basis: 40px;
      margin-left: 15px;
      margin-right: 16px;
      @include mq-sp {
        height: 40px;
        flex-basis: 40px;
      }
    }

    &image {
      width: 40px;
      height: 40px;
      display: block;
      background-size: cover;
      border-radius: 20px;
      background-position: 50%;
      overflow: hidden;
      transition: none;
      @include mq-sp {
        width: 40px;
        height: 40px;
      }
    }

    &text {
      flex: 1;
      white-space: nowrap;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 73px;
    }

    &ob-visit-type {
      &-label {
        display: inline-flex;
        margin-top: 8px;
        border-radius: 2px;
        padding: 1px 3px;
        line-height: 16px;
        font-size: 10px;
        font-weight: bold;
        border: 1px solid $color-lightgray;
        color: $basecolor-primary;
        background-color: $color-lightgray;

        &-- {
          &only-offline {
            @extend .message-detail-header__ob-visit-type-label;
            border: 1px solid $basecolor-primary;
            color: $basecolor-primary;
            background-color: $color-white;
          }

          &only-video {
            @extend .message-detail-header__ob-visit-type-label;
            border: 1px solid $color-temp-primary;
            color: $color-white;
            background-color: $basecolor-primary;
          }
        }
      }
    }

    &button-list {
      display: flex;
      box-sizing: border-box;
      background: $bgcolor-normal;
      height: $header-height;
      padding-left: 15px;
      justify-content: flex-end;
      align-items: center;

      @include mq-sp {
        width: 100%;
      }

      &__ {
        &item {
          display: flex;
          justify-content: center;
          flex-direction: column;

          &__ {
            &button-video-chat {
              width: 44px;
              height: 44px;
              display: block;
              background-image: url(/assets/img/message/message-icon-video-chat.svg);
              background-repeat: no-repeat;
              background-position: center;
            }

            &button-video-chat-offline {
              width: 44px;
              height: 44px;
              display: block;
              background-image: url(/assets/img/message/message-icon-video-chat-offline.svg);
              background-repeat: no-repeat;
              background-position: center;
            }
          }
        }

        &button {
          & + & {
            margin-left: 8px;
          }

          &--start {
            position: relative;
          }
        }
      }
    }

    &menu {
      position: absolute;
      top: 48px;
      right: 16px;
      width: 177px;
      background-color: $bgcolor-normal;
      box-shadow: 0 10px 24px $shadow-28;
      border: $basecolor-shadow;
      border-radius: 18px;
      z-index: 10;
      opacity: 0;
      transition: all 0.5s;
      pointer-events: none;
      @include mq-sp {
        top: 56px;
      }

      &.is-active {
        display: block;
        opacity: 1;
        pointer-events: auto;
      }

      li a {
        display: block;
        padding: 15px 24px;
        color: $fgcolor-normal;
        font-size: 15px;
        line-height: 15px;

        &:hover {
          background-color: $bgcolor-hover;
          text-decoration: none;
        }
      }

      li:not(:last-child):after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $color-gray;
      }

      &--button {
        width: 18px;
        height: 18px;
        display: block;
        margin: 0 16px 0 8px;
        background-image: url(/assets/img/message/message-icon-etc.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .primary-modal-button {
    $button-height: 34px;

    border-radius: 17px;
    height: $button-height;
    font-size: 14px;
    line-height: 33px;
    padding: 0 16px;
    margin: 0;
    box-sizing: border-box;
    @include mq-sp {
      width: 112px;
      font-size: 13px;

      & + & {
        margin: 0 0 4px 0;

      }
    }

    @include mq-pc {
      & + & {
        margin-left: 8px;
      }
    }

    &-video-start {
      background: $bgcolor-button-primary url("/assets/icon/icon-camera.svg") no-repeat 14px 8px;
      background-size: 16px;
      padding-left: 34px;
      width: 100%;
      font-size: 14px;

      &--disabled {
        display: block;
        background: $color-darkgray url("/assets/icon/icon-camera-disabled.svg") no-repeat 14px 8px;
        color: $color-white;
        cursor: default;
      }

      &:not(&--disabled):hover {
        color: lighten($fgcolor-button-primary, 20%);
        border-color: lighten($bdcolor-button-secondary, 20%);
        background-color: lighten($bgcolor-button-primary, 20%);
      }

      &__tool-tip {
        $arrow-half-width: 11px;
        $text-height: 15px;

        display: inline-block;
        position: absolute;
        top: $button-height + $arrow-half-width + 4px;
        right: calc(-50% + #{$arrow-half-width});
        font-size: 12px;
        color: $basecolor-primary;
        width: 219px;
        height: 41px;
        line-height: 21px;
        border: 1px solid $basecolor-primary;
        padding: 10px;
        box-sizing: border-box;
        background-color: $bgcolor-normal;
        z-index: 100;

        &--enabled {
          display: inline-block;
        }

        p {
          // フォントサイズによって15px弱ですが計算しやすくするために固定する
          height: $text-height;
        }

        &:after, &:before {
          right: calc(50% - #{$arrow-half-width});
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-bottom-color: $bdcolor-reverse;
          border-width: $arrow-half-width;
          top: -(($arrow-half-width * 2) - 1);
        }

        &:before {
          border-bottom-color: $bdcolor-primary-theme;
          border-width: $arrow-half-width;
          top: -($arrow-half-width * 2);
        }
      }
    }

    &-comment {
      background: $fgcolor-button-primary url("/assets/icon/speech-bubble.svg") no-repeat 14px 8px;
      background-size: 16px;
      padding-left: 34px;
      width: 100%;
      font-size: 14px;

      &:hover {
        background-color: $bgcolor-button-secondary-hover;
      }
    }
  }
}

.message-detail-title {
  display: flex;
  align-items: center;

  &--text {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    line-height: 12px;
    color: $fgcolor-normal;

    &:hover {
      text-decoration: none;
      color: $fgcolor-normal;
    }
  }

  &--arrow {
    content: "";
    background: url("/assets/icon/arrow-right-small.svg") no-repeat 100% 50%;
    background-size: 6px 10px;
    display: inline-block;
    width: 13px;
    height: 10px;
    @include mq-sp {
      width: 10px;
    }
  }
}

.message-detail-subtitle {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-top: 4px;
  font-size: $font-size-smaller;
  white-space: nowrap;
  line-height: 12px;
  color: $color-darkgray;;

  &:hover {
    text-decoration: none;
    color: $font-size-smaller;
  }

  &:not(:empty):after {
    content: "";
    background: url("/assets/icon/arrow-right-small.svg") no-repeat 100% 50%;
    background-size: 6px 10px;
    display: inline-block;
    width: 13px;
    height: 10px;
    @include mq-sp {
      width: 10px;
    }
  }

  &--without-link {
    &:after {
      display: none;
    }

    &:not(:empty):after {
      display: none;
    }
  }
}

.message-detail-scrollable {
  overflow: scroll;
  height: calc(100% - 56px);
}

.message-detail-info {
  padding: 16px;
  background-color: $color-lightgray;
  box-sizing: border-box;
  line-height: 24px;
  font-size: $font-size-small;
  color: $color-darkgray;

  &__ {
    &item {
      &:before {
        content: "・";
      }
    }

    &text {
      text-align: center;
    }
  }
}

.message-detail-caution {
  padding: 16px;
  background-color: $color-lightyellow;
  box-sizing: border-box;
  line-height: 24px;
  font-size: $font-size-small;
  color: $color-black;

  > img {
    vertical-align: text-bottom;
  }
}

.message-scroll-wrapper {
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;

  @include mq-sp {
    -webkit-overflow-scrolling: touch;
  }
}

.comment-recommend-check {
  position: relative;
  flex-basis: 66px;
  height: 34px;
  margin-right: 8px;
}
